import { getNextSpecimenOrder } from "./helpers";
import { getDefaultStyles } from "./getDefaultStylesByField";
import store from "@/store";

export default function handleLoadOrder({
  order,
  caseDetails,
  specimenNumberingType,
  phoneTypes,
  sexes,
  labProtocols = [],
  prefixProtocols = [],
  prefixDefaultProtocolId
}) {
  //Translate interface keys to IpCaseDetails dto keys.
  for (let key of [
    "medicalRecordNum",
    "firstName",
    "lastName",
    "middleName",
    "bornOn",
    "socialSecurityNum",
    "sexId",
    "priorityId",
    "raceId",
    "referenceNum",
    "collectedOn",
    "accountNum",
    "title",
    "orderNum",
    "alternateId"
  ]) {
    if (order[key] !== undefined && order[key] !== null) {
      let value = order[key];
      switch (key) {
        case "firstName":
          key = "patientFirstName";
          break;
        case "lastName":
          key = "patientLastName";
          break;
        case "middleName":
          key = "patientMiddleName";
          break;
        case "bornOn":
          key = "patientDOB";
          break;
        case "sexId": {
          if (value) {
            const matcher = new RegExp(`${order[key]}`, "i");
            const targetSex = sexes.find(e => matcher.test(e.displayName));
            value = targetSex?.id;
            key = "patientSex";
          }
          break;
        }
        case "orderNum":
          key = "orderNumber";
          break;
        case "medicalRecordNum":
          key = "patientMRN";
          break;
        case "priorityId":
          key = "priority";
          break;
        case "socialSecurityNum": {
          key = "patientSSN";
          break;
        }
        case "referenceNum":
          key = "refNumber";
          break;
        case "accountNum":
          key = "patientAccountNumber";
          caseDetails.patientAccountNumber = value;
          break;
        case "collectedOn":
          if (store.state.labSettings?.UseADTOrders) {
            value = null;
          }
          break;
        default:
          break;
      }
      caseDetails[key] = value;
    }
  }
  caseDetails.phoneNumbers = [];
  if (order.homePhone) {
    const type = phoneTypes.find(phoneType => /home/i.test(phoneType.displayName));
    caseDetails.phoneNumbers.push({
      phoneTypeId: type?.id,
      phoneNumber: order.homePhone,
      isPrimary: true
    });
  }
  if (order.cellPhone) {
    const type = phoneTypes.find(phoneType => /cell/i.test(phoneType.displayName));
    caseDetails.phoneNumbers.push({
      phoneTypeId: type?.id,
      phoneNumber: order.cellPhone,
      isPrimary: !caseDetails.phoneNumbers?.length > 1
    });
  }
  if (order.email) {
    caseDetails.emails = [{ email: order.email, isPrimary: true }];
  }
  const { address1, address2, city, zipCode, stateId, hL7Specimen, provider } = order;
  if (address1 && typeof stateId === "number" && zipCode) {
    const orderAddress = { line1: address1, line2: address2, city, zipCode, state: stateId };
    caseDetails.addresses = [orderAddress];
  }

  //Load contacts
  if (provider?.id || provider?.contactId) {
    if (Array.isArray(caseDetails.contacts)) {
      caseDetails.contacts = [
        ...caseDetails.contacts.reduce((acc, curr) => {
          curr.isPrimary = false;
          if (acc.findIndex(e => e.id === curr.id)) {
            return acc;
          }
          return [...acc, curr];
        }, []),
        { ...provider, isPrimary: true }
      ];
    } else {
      caseDetails.contacts = [{ ...provider, isPrimary: true }];
    }
    if (provider?.defaultPathologistId && !caseDetails?.pathologists?.length) {
      const pathologist = store.state.dropdowns.pathologists.find(
        e => e.id === provider?.defaultPathologistId
      );
      if (pathologist) {
        caseDetails.pathologists = [{ ...pathologist, isPrimary: true }];
      }
    }
  }
  const specimens = [];
  hL7Specimen.forEach(specimen => {
    let gross = "";
    let { site, clinicalText, protocolId, clinicalICDCodes } = specimen;
    // the labProtocols doesn't have all the data we need, so we have to do this if there is a prefix default protocol.
    let targetPrefixProtocol = {};
    if (!protocolId && prefixDefaultProtocolId) {
      protocolId = prefixDefaultProtocolId;
      targetPrefixProtocol = prefixProtocols.find(e => e.id === prefixDefaultProtocolId);
    }
    if (protocolId) {
      const targetProtocol = labProtocols.find(protocol => protocol.macroId === protocolId);
      if (targetProtocol?.gross) {
        gross = `<span style="${getDefaultStyles("gross")}">${targetProtocol.gross}</span>`;
      }
    }
    specimens.push({
      specimenOrder: getNextSpecimenOrder(specimens, specimenNumberingType),
      site: site && site.trim(),
      clinical: clinicalText
        ? `<span style="${getDefaultStyles("clinical")}">${clinicalText}</span>`
        : "",
      gross,
      protocolId,
      clinicalICDCodes,
      numberOfBlocks: targetPrefixProtocol?.blocks || 1,
      slides: targetPrefixProtocol?.slides || null
    });
  });
  caseDetails.specimens = specimens;
  caseDetails.numberOfSpecimens = hL7Specimen.length || 1;
  caseDetails.caseCreationMethodId = 2;
  return caseDetails;
}
